import validate from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/packages/authLayer/middleware/auth.global.ts";
import _01_45role_45global from "/app/apps/admin/middleware/01.role.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  _01_45role_45global
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.8_@babel+core@7.26._b7715fc8908df997c114325e55ba2c5a/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}