export default defineNuxtPlugin(() => {
    const { token } = useAuth();
    const paymentApiUrl = usePaymentApiUrl();

    const $api = $fetch.create({
        baseURL: paymentApiUrl,
        onRequest({ options }) {
            if (token) {
                // Add Authorization header
                options.headers = new Headers(options.headers);
                options.headers.set('Authorization', `${token.value}`);
            }
        },
        async onResponseError({ response }) {
            if (response.status === 401) {
                await navigateTo('/auth/login')
            }
        }
    });
    return {
        provide: {
            paymentApiV1Url: paymentApiUrl,
            paymentApiV1: $api
        }
    }
})
