export default defineNuxtRouteMiddleware(async (to) => {
    const {status} = useAuth();

    if (status.value === 'authenticated') {
        return;
    }

    if (to.path === '/auth/login') {
        return;
    }

    sessionStorage.setItem('redirectAuthenticated', to.fullPath);
    return navigateTo('/auth/login');
});