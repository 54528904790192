
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexW7ePgGEC0iMeta } from "/app/apps/admin/pages/accounts-logs/index.vue?macro=true";
import { default as indexu37Mv91famMeta } from "/app/apps/admin/pages/accounts/index.vue?macro=true";
import { default as _91id_93Kg3h1WtENoMeta } from "/app/apps/admin/pages/affiliates/[id].vue?macro=true";
import { default as createjH9MB2mlxfMeta } from "/app/apps/admin/pages/affiliates/create.vue?macro=true";
import { default as index6ANXHRGX0ZMeta } from "/app/apps/admin/pages/affiliates/index.vue?macro=true";
import { default as loginmNRU5AbfbnMeta } from "/app/apps/admin/pages/auth/login.vue?macro=true";
import { default as index1sKXKiOnAvMeta } from "/app/apps/admin/pages/finance/index.vue?macro=true";
import { default as indexRhbCo9xKafMeta } from "/app/apps/admin/pages/index.vue?macro=true";
import { default as _91id_938P31KSEikOMeta } from "/app/apps/admin/pages/merchants/[id].vue?macro=true";
import { default as createEoHtO5AzvfMeta } from "/app/apps/admin/pages/merchants/create.vue?macro=true";
import { default as indexKvKHtgwECbMeta } from "/app/apps/admin/pages/merchants/index.vue?macro=true";
import { default as _91id_93bRNHUGgA7rMeta } from "/app/apps/admin/pages/providers/[id].vue?macro=true";
import { default as createvLXDhvsderMeta } from "/app/apps/admin/pages/providers/create.vue?macro=true";
import { default as indexPUaD5RluVQMeta } from "/app/apps/admin/pages/providers/index.vue?macro=true";
import { default as indexLSaiCBNZedMeta } from "/app/apps/admin/pages/refs/index.vue?macro=true";
import { default as routingp6SDuVcNzxMeta } from "/app/apps/admin/pages/routing.vue?macro=true";
import { default as indexmTxcUyPu0eMeta } from "/app/apps/admin/pages/tariffs/index.vue?macro=true";
import { default as indexGb8nJ9Auq2Meta } from "/app/apps/admin/pages/transactions/index.vue?macro=true";
import { default as _91id_93zmyL9SuQbzMeta } from "/app/apps/admin/pages/users/[id].vue?macro=true";
import { default as createrzDewVk25SMeta } from "/app/apps/admin/pages/users/create.vue?macro=true";
import { default as indeximPu7gfKFmMeta } from "/app/apps/admin/pages/users/index.vue?macro=true";
export default [
  {
    name: "accounts-logs",
    path: "/accounts-logs",
    meta: indexW7ePgGEC0iMeta || {},
    component: () => import("/app/apps/admin/pages/accounts-logs/index.vue")
  },
  {
    name: "accounts",
    path: "/accounts",
    meta: indexu37Mv91famMeta || {},
    component: () => import("/app/apps/admin/pages/accounts/index.vue")
  },
  {
    name: "affiliates-id",
    path: "/affiliates/:id()",
    meta: _91id_93Kg3h1WtENoMeta || {},
    component: () => import("/app/apps/admin/pages/affiliates/[id].vue")
  },
  {
    name: "affiliates-create",
    path: "/affiliates/create",
    meta: createjH9MB2mlxfMeta || {},
    component: () => import("/app/apps/admin/pages/affiliates/create.vue")
  },
  {
    name: "affiliates",
    path: "/affiliates",
    meta: index6ANXHRGX0ZMeta || {},
    component: () => import("/app/apps/admin/pages/affiliates/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginmNRU5AbfbnMeta || {},
    component: () => import("/app/apps/admin/pages/auth/login.vue")
  },
  {
    name: "finance",
    path: "/finance",
    meta: index1sKXKiOnAvMeta || {},
    component: () => import("/app/apps/admin/pages/finance/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexRhbCo9xKafMeta || {},
    component: () => import("/app/apps/admin/pages/index.vue")
  },
  {
    name: "merchants-id",
    path: "/merchants/:id()",
    meta: _91id_938P31KSEikOMeta || {},
    component: () => import("/app/apps/admin/pages/merchants/[id].vue")
  },
  {
    name: "merchants-create",
    path: "/merchants/create",
    meta: createEoHtO5AzvfMeta || {},
    component: () => import("/app/apps/admin/pages/merchants/create.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    meta: indexKvKHtgwECbMeta || {},
    component: () => import("/app/apps/admin/pages/merchants/index.vue")
  },
  {
    name: "providers-id",
    path: "/providers/:id()",
    meta: _91id_93bRNHUGgA7rMeta || {},
    component: () => import("/app/apps/admin/pages/providers/[id].vue")
  },
  {
    name: "providers-create",
    path: "/providers/create",
    meta: createvLXDhvsderMeta || {},
    component: () => import("/app/apps/admin/pages/providers/create.vue")
  },
  {
    name: "providers",
    path: "/providers",
    meta: indexPUaD5RluVQMeta || {},
    component: () => import("/app/apps/admin/pages/providers/index.vue")
  },
  {
    name: "refs",
    path: "/refs",
    meta: indexLSaiCBNZedMeta || {},
    component: () => import("/app/apps/admin/pages/refs/index.vue")
  },
  {
    name: "routing",
    path: "/routing",
    meta: routingp6SDuVcNzxMeta || {},
    component: () => import("/app/apps/admin/pages/routing.vue")
  },
  {
    name: "tariffs",
    path: "/tariffs",
    meta: indexmTxcUyPu0eMeta || {},
    component: () => import("/app/apps/admin/pages/tariffs/index.vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: indexGb8nJ9Auq2Meta || {},
    component: () => import("/app/apps/admin/pages/transactions/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93zmyL9SuQbzMeta || {},
    component: () => import("/app/apps/admin/pages/users/[id].vue")
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: createrzDewVk25SMeta || {},
    component: () => import("/app/apps/admin/pages/users/create.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indeximPu7gfKFmMeta || {},
    component: () => import("/app/apps/admin/pages/users/index.vue")
  }
]